import { ShipdaCurrentLanguage } from "@sellernote/shared/src/i18n/i18nForShipda";
import {
  UserPort,
  WareHouse,
} from "@sellernote/shared/src/types/common/common";
import WarehouseIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/WarehouseIcon";

import { InputSearchWithPortOption } from "../types";

/**
 * 창고 옵션 리스트의 Label 값을 만드는 함수
 */
const getWarehouseLabel = (warehouse: WareHouse) => {
  const { name, nameEN } = warehouse;
  return ShipdaCurrentLanguage.currentLanguage === "ko"
    ? `${name}창고 | ${nameEN} Warehouse (CNWEI)`
    : `${nameEN} Warehouse (CNWEI)`;
};

/**
 * 창고 옵션 리스트의 Label, Value 값을 만드는 함수
 */
const getWarehouseOptionList = (
  warehouseList: WareHouse[]
): InputSearchWithPortOption<string>[] => {
  if (!warehouseList.length) return [];

  return warehouseList.map((warehouse) => {
    const label = getWarehouseLabel(warehouse);

    return {
      label,
      value: warehouse.displayAddressCN,
      iconInfo: {
        Icon: WarehouseIcon,
        position: "left",
      },
      portInfo: warehouse,
    };
  });
};

/**
 * 항구/공항 옵션리스트의 Label, Value 값을 만드는 함수
 */
const getPortLabel = ({
  code,
  name,
  nameEN,
  nameC,
}: Pick<UserPort, "code" | "name" | "nameEN" | "nameC">) => {
  const nameLabelField = (() => {
    if (!name && !nameC) return "";

    return `${name || nameC}`;
  })();

  const portLabelField = `${nameEN} (${code})`;

  return nameLabelField
    ? ShipdaCurrentLanguage.currentLanguage === "ko"
      ? `${nameLabelField} | ${portLabelField}`
      : portLabelField
    : portLabelField;
};

/**
 * 선택한 옵션이 창고 옵션인지 확인하는 함수
 */
const checkIsWarehouse = (
  portInfo: UserPort | WareHouse | undefined
): portInfo is WareHouse => {
  if (!portInfo) return false;

  return "recipientName" in portInfo;
};

export {
  getWarehouseLabel,
  getWarehouseOptionList,
  getPortLabel,
  checkIsWarehouse,
};
